// You can customize the template with the help of this file

//Template config options
const mainConfig = {
  app: {
    appName: 'SmartParley',
    appLogoImage: require('@src/assets/images/logo/logo.png').default
  },
  layout: {
    skin: 'light',
    routerTransition: 'fadeIn',
    type: 'horizontal',
    contentWidth: 'full',
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      type: 'floating',
      backgroundColor: 'white'
    },
    footer: {
      type: 'fixed'
    },
    scrollTop: true
  }
}

export default mainConfig
