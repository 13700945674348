
import { useState, createContext } from 'react'
import { IntlProvider } from 'react-intl'
import messagesEn from '@assets/data/locales/en.json'
import messagesDe from '@assets/data/locales/de.json'

const menuMessages = {
  en: { ...messagesEn },
  de: { ...messagesDe }
}

const Context = createContext()

const IntlProviderWrapper = ({ children }) => {
  const [locale, setLocale] = useState('de')
  const [messages, setMessages] = useState(menuMessages['de'])

  localStorage.setItem('locale', locale)

  const switchLanguage = lang => {
    setLocale(lang)
    setMessages(menuMessages[lang])
  }

  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale='de'>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
